<template>
    <div class="row justify-content-center">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
            <h1 class="mt-4 font-weight-bolder"><i class="fas fa-plus mr-3"></i>Create New Courses</h1>
            <hr>
            <div class="row">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-5 p-auto">
                    <div class="card w-100 p-0 shadow-xss border-0 rounded-lg overflow-hidden mr-1 p-3">
                        <form @submit.prevent="postCourse" class="row p-2" style="z-index: 99999">
                            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12">
                                <div class="row p-auto">
                                    <div class="col-xl-12">
                                        <div class="avatar-upload"  v-show="!edit">
                                            <div class="text-center p-2">
                                                <label for="avatar">
                                                    <img width="200" :src="files.length ? files[0].url : 'https://www.gravatar.com/avatar/default?s=200&r=pg&d=mm'" />
                                                </label>
                                            </div>
                                            <div class="text-center p-2">
                                                <file-upload
                                                extensions="jpg,jpeg,png"
                                                accept="image/png,image/jpeg,image/jpg"
                                                name="avatar"
                                                class="btn btn-current"
                                                :drop="!edit"
                                                v-model="files"
                                                @input-filter="inputFilter"
                                                @input-file="inputFile"
                                                ref="upload" required>
                                                Upload Banner
                                                </file-upload>
                                            </div>
                                        </div>

                                        <div class="avatar-edit" v-show="files.length && edit">
                                            <div class="avatar-edit-image" v-if="files.length">
                                                <cropper
                                                    class="cropper"
                                                    ref="cropper"
                                                    :src="files[0].url"
                                                    :stencil-props="{
                                                        handlers: {},
                                                        movable: false,
                                                        scalable: false,
                                                        aspectRatio: 4 / 3,
                                                    }"
                                                    :resize-image="{
                                                        adjustStencil: false
                                                    }"
                                                    image-restriction="stencil"
                                                ></cropper>
                                            </div>
                                            <div class="text-center p-4">
                                                <button type="button" class="btn btn-default" @click.prevent="$refs.upload.clear">Cancel</button>
                                                <button type="submit" class="btn btn-current" @click.prevent="editSave">Save</button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-lg-12 form-group mb-3">
                                        <label for="">Course Name</label>
                                        <input v-model="form.name" type="text" required class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Name">
                                    </div>
                                    <div class="col-lg-6 form-group mb-3">
                                        <label for="">Course Category</label>
                                        <select @change="changeCategory()" v-model="form.category" class="form-control text-grey-900 font-xsss fw-600" required>
                                            <option value="" disabled selected>-- Select your Course Category --</option>
                                            <option value="SHINE">SHINE</option>
                                            <option value="GLORY">GLORY</option>
                                            <option value="TRIUMPH">TRIUMPH</option>
                                            <option value="EXCLUSIVE">EXCLUSIVE</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-6 form-group mb-3">
                                        <label for="">Level</label>
                                        <select v-model="form.level" class="form-control text-grey-900 font-xsss fw-600" required>
                                            <option value="" disabled selected>-- Select your Course Level --</option>
                                            <option value="1">1</option>
                                            <option value="2">2</option>
                                            <option value="3">3</option>
                                            <option value="4" v-if="form.category == 'SHINE' || form.category == 'EXCLUSIVE'">4</option>
                                            <option value="5" v-if="form.category == 'SHINE'">5</option>
                                        </select>
                                    </div>
                                    <div class="col-lg-12 form-group mb-3">
                                        <label for="">Description</label>
                                        <textarea v-model="form.description" type="text" required class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Description"></textarea>                       
                                    </div>
                                    <div class="col-lg-12 form-group mb-3">
                                        <label for="">Objective</label>
                                        <vue-editor v-model="form.objective" required/>
                                    </div>
                                    <div class="col-12"><hr></div>
                                    <div class="col-lg-12 form-group">
                                        <div class="form-check">
                                            <input type="checkbox" v-model="form.has_special" class="form-check-input mt-2" id="checkSpecial">
                                            <a href="javascript:void(0)" class="form-check-label font-xsss fw-500 text-grey-700" for="checkSpecial">Has Special Course</a>
                                        </div>
                                    </div>
                                    <div class="col-12 mb-2" v-if="form.has_special">
                                        <ul class="nav nav-tabs">
                                            <li class="nav-item mr-1">
                                                <a class="nav-link fw-600 font-xs text-primary" :class="{'active': tab == 1}" href="javascript:void(0)" @click="tab = 1">Regular</a>
                                            </li>
                                            <li class="nav-item">
                                                <a class="nav-link fw-600 font-xs text-current" :class="{'active': tab == 2}" href="javascript:void(0)" @click="tab = 2">Special Feature</a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div class="col-12">
                                        <div class="row" v-if="tab == 1">
                                            <div class="col-lg-12 form-group mb-3">
                                                <label for="">Price All Cycle</label>
                                                <InputCurrency :className="'form-control text-grey-900 font-xsss fw-600'" v-model="form.price" :value="form.price" :place-holder="'Enter your Course Price'" :is-required="true"></InputCurrency>
                                            </div>
                                            <div class="col-lg-12">
                                                <hr>
                                                <label for="" class="text-primary font-xs fw-600">Cycles Price</label>
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <div class="form-check">
                                                    <input type="checkbox" v-model="form.cycles[0]" class="form-check-input mt-2" id="checkCycle">
                                                    <a href="javascript:void(0)" class="form-check-label font-xsss fw-500 text-grey-700" for="checkCycle">Cycle 1</a>
                                                </div>
                                                <InputCurrency :className="'form-control text-grey-900 font-xsss fw-600'" v-model="form.price_cycle_1" :value="form.price_cycle_1" :place-holder="'Enter your Course Price Cycle 1'" :is-required="true"></InputCurrency>
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <label for="">Cycles 1 Start Date</label>
                                                <input v-model="form.start_date_1" type="date" required class="form-control text-grey-900 font-xsss fw-600">
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <label for="">Cycles 1 End Date</label>
                                                <input v-model="form.end_date_1" type="date" required class="form-control text-grey-900 font-xsss fw-600">
                                            </div>
                                            <div class="col-12">
                                                <hr>
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <div class="form-check">
                                                    <input type="checkbox" v-model="form.cycles[1]" class="form-check-input mt-2" id="checkCycle">
                                                    <a href="javascript:void(0)" class="form-check-label font-xsss fw-500 text-grey-700" for="checkCycle">Cycle 2</a>
                                                </div>
                                                <InputCurrency :className="'form-control text-grey-900 font-xsss fw-600'" v-model="form.price_cycle_2" :value="form.price_cycle_2" :place-holder="'Enter your Course Price Cycle 2'" :is-required="true"></InputCurrency>
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <label for="">Cycles 2 Start Date</label>
                                                <input v-model="form.start_date_2" type="date" required class="form-control text-grey-900 font-xsss fw-600">
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <label for="">Cycles 2 End Date</label>
                                                <input v-model="form.end_date_2" type="date" required class="form-control text-grey-900 font-xsss fw-600">
                                            </div>
                                            <div class="col-12">
                                                <hr>
                                            </div>
                                            <div class="col-lg-12 form-group mb-3">
                                                <div class="form-check">
                                                    <input type="checkbox" v-model="form.cycles[2]" class="form-check-input mt-2" id="checkCycle">
                                                    <a href="javascript:void(0)" class="form-check-label font-xsss fw-500 text-grey-700" for="checkCycle">Cycle 3</a>
                                                </div>
                                                <InputCurrency :className="'form-control text-grey-900 font-xsss fw-600'" v-model="form.price_cycle_3" :value="form.price_cycle_3" :place-holder="'Enter your Course Price Cycle 2'" :is-required="true"></InputCurrency>
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <label for="">Cycles 3 Start Date</label>
                                                <input v-model="form.start_date_3" type="date" required class="form-control text-grey-900 font-xsss fw-600">
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <label for="">Cycles 3 End Date</label>
                                                <input v-model="form.end_date_3" type="date" required class="form-control text-grey-900 font-xsss fw-600">
                                            </div>
                                        </div>
                                        <div class="row" v-if="tab == 2">
                                            <div class="col-lg-12 form-group mb-3">
                                                <label for="">Price All Cycle Special</label>
                                                <InputCurrency :className="'form-control text-grey-900 font-xsss fw-600'" v-model="form.price_special" :value="form.price_special" :place-holder="'Enter your Course Price'" :is-required="true"></InputCurrency>
                                            </div>
                                            <div class="col-lg-12">
                                                <hr>
                                                <label for="" class="text-current font-xs fw-600">Cycles Special Price</label>
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <div class="form-check">
                                                    <input type="checkbox" v-model="form.cycles[0]" class="form-check-input mt-2" id="checkCycle" disabled>
                                                    <a href="javascript:void(0)" class="form-check-label font-xsss fw-500 text-grey-700" for="checkCycle">Cycle Special 1</a>
                                                </div>
                                                <InputCurrency :className="'form-control text-grey-900 font-xsss fw-600'" v-model="form.price_special_cycle_1" :value="form.price_special_cycle_1" :place-holder="'Enter your Course Price Cycle 1'" :is-required="true"></InputCurrency>
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <label for="">Cycles Special 1 Start Date</label>
                                                <input v-model="form.start_date_1" type="date" required class="form-control text-grey-900 font-xsss fw-600" disabled>
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <label for="">Cycles Special 1 End Date</label>
                                                <input v-model="form.end_date_1" type="date" required class="form-control text-grey-900 font-xsss fw-600" disabled>
                                            </div>
                                            <div class="col-12">
                                                <hr>
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <div class="form-check">
                                                    <input type="checkbox" v-model="form.cycles[1]" class="form-check-input mt-2" id="checkCycle" disabled>
                                                    <a href="javascript:void(0)" class="form-check-label font-xsss fw-500 text-grey-700" for="checkCycle">Cycle Special 2</a>
                                                </div>
                                                <InputCurrency :className="'form-control text-grey-900 font-xsss fw-600'" v-model="form.price_special_cycle_2" :value="form.price_special_cycle_2" :place-holder="'Enter your Course Price Cycle 2'" :is-required="true"></InputCurrency>
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <label for="">Cycles Special 2 Start Date</label>
                                                <input v-model="form.start_date_2" type="date" required class="form-control text-grey-900 font-xsss fw-600" disabled>
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <label for="">Cycles Special 2 End Date</label>
                                                <input v-model="form.end_date_2" type="date" required class="form-control text-grey-900 font-xsss fw-600" disabled>
                                            </div>
                                            <div class="col-12">
                                                <hr>
                                            </div>
                                            <div class="col-lg-12 form-group mb-3">
                                                <div class="form-check">
                                                    <input type="checkbox" v-model="form.cycles[2]" class="form-check-input mt-2" id="checkCycle" disabled>
                                                    <a href="javascript:void(0)" class="form-check-label font-xsss fw-500 text-grey-700" for="checkCycle">Cycle Special 3</a>
                                                </div>
                                                <InputCurrency :className="'form-control text-grey-900 font-xsss fw-600'" v-model="form.price_special_cycle_3" :value="form.price_special_cycle_3" :place-holder="'Enter your Course Price Cycle 2'" :is-required="true"></InputCurrency>
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <label for="">Cycles Special 3 Start Date</label>
                                                <input v-model="form.start_date_3" type="date" required class="form-control text-grey-900 font-xsss fw-600" disabled>
                                            </div>
                                            <div class="col-lg-12 form-group">
                                                <label for="">Cycles Special 3 End Date</label>
                                                <input v-model="form.end_date_3" type="date" required class="form-control text-grey-900 font-xsss fw-600" disabled>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <hr>
                                    </div>
                                    <div class="col-lg-12 form-group mb-3">
                                        <label for="">Length</label>
                                        <input v-model="form.length" type="number" required class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Length">
                                    </div>
                                    <!-- <div class="col-lg-12 form-group mb-3">
                                        <label for="">Start Date</label>
                                        <input v-model="form.start_date" type="date" required class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Start Date">
                                    </div> -->
                                    <div class="col-lg-6 form-group mb-3">
                                        <label for="">Start Time</label>
                                        <input v-model="form.start_time" type="time" required class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Start Time">
                                    </div>
                                    <div class="col-lg-6 form-group mb-3">
                                        <label for="">End Time</label>
                                        <input v-model="form.end_time" type="time" required class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course End Time">
                                    </div>
                                    <div class="col-lg-12 form-group mb-3">
                                        <div class="form-check">
                                            <input type="checkbox" v-model="usedPoint" class="form-check-input mt-2" id="exampleCheck2">
                                            <a href="javascript:void(0)" @click="usedPoint = !usedPoint" class="form-check-label font-xsss fw-500 text-grey-700" for="exampleCheck2">Add Course Point</a>
                                        </div>
                                    </div>
                                    <div class="col-12" v-if="usedPoint">
                                        <div class="row">
                                            <div class="col-12">
                                                <h3 class="fw-500 font-lg text-black">Course Point</h3>
                                            </div>
                                            <div class="col-lg-6 form-group mb-3">
                                                <label for="">Point Type</label>
                                                <select @change="form.point = 0" v-model="form.type" class="form-control text-grey-900 font-xsss fw-600" required>
                                                    <option value="" disabled selected>-- Select your Course Point Type --</option>
                                                    <option value="PERCENT">Percent</option>
                                                    <option value="AMOUNT">Amount</option>
                                                </select>
                                            </div>
                                            <div class="col-lg-6 form-group mb-3">
                                                    <label for="">Point Status</label>
                                                    <select  v-model="form.point_status" class="form-control text-grey-900 font-xsss fw-600" required>
                                                        <option :value="1">Active</option>
                                                        <option :value="0">Inactive</option>
                                                    </select>
                                                </div>
                                            <div class="col-lg-6 form-group mb-3">
                                                <label for="">{{form.type == 'PERCENT' ? 'Point Percent' : 'Point Amount'}}</label>
                                                <input v-model="form.point" v-if="form.type == 'PERCENT'" max="100" min="0" type="number" class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Point" required>
                                                <input v-model="form.point" v-else type="number" min="0" class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Point" required>
                                            </div>
                                            <div class="col-lg-6 form-group mb-3">
                                                <label for="">Used Limit</label>
                                                <input v-model="form.used_limit" type="number" class="form-control text-grey-900 font-xsss fw-600" placeholder="Enter your Course Point Used Limit" required>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-12 form-group text-right my-4">
                                        <router-link to="/admin/courses" class="btn btn-outline-warning mr-4" value="Submit">Cancel</router-link>
                                        <input type="Submit" class="btn btn-current" value="Submit">
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>
<style  scoped>
.cropper {
  height: 600px;
  background: #DDD;
}
</style>
<script>
    import axios from 'axios'
    import { Cropper } from 'vue-advanced-cropper'
    import 'vue-advanced-cropper/dist/style.css';
    import FileUpload from 'vue-upload-component'
    import InputCurrency from '../../../components/helpers/InputCurrency.vue';
    export default {
        name: "CreateCourses",
        components: {
            FileUpload,
            Cropper,
            InputCurrency
        },
        data(){
            return {
                user: {},
                tab: 1,
                form: {
                    name: '',
                    category: '',
                    level: '',
                    description: '',
                    price: '',
                    price_special: '',
                    objective: '',
                    length: '',
                    banner: '',
                    // start_date: '',
                    start_time: '',
                    end_time: '',
                    point: '',
                    used_limit: '',
                    type: '',
                    has_special: false,
                    price_cycle_1: '',
                    price_cycle_2: '',
                    price_cycle_3: '',
                    price_special_cycle_1: '',
                    price_special_cycle_2: '',
                    price_special_cycle_3: '',
                    start_date_1: '',
                    end_date_1: '',
                    start_date_2: '',
                    end_date_2: '',
                    start_date_3: '',
                    end_date_3: '',
                    point_status: 1,
                    cycles: [true, true, true]
                },
                edit: false,
                usedPoint: false,
                files:[],
                img: 'https://images.pexels.com/photos/226746/pexels-photo-226746.jpeg',
                validationErrors: '',
            }
        },

        methods: {
            changeCategory(){
                this.form.level = '';
            },
            async editSave() {
                this.edit = false
                let oldFile = this.files[0]
                const result = this.$refs.cropper.getResult();
                let binStr = atob(result.canvas.toDataURL(oldFile.type).split(',')[1])
                let arr = new Uint8Array(binStr.length)
                for (let i = 0; i < binStr.length; i++) {
                    arr[i] = binStr.charCodeAt(i)
                }
                let file = new File([arr], oldFile.name, { type: oldFile.type })
                this.$refs.upload.update(oldFile.id, {
                    file,
                    type: file.type,
                    size: file.size,
                    active: true,
                })
                
                let data = new FormData();
                data.append('dir', 'courses/banner');
                data.append('attachment', this.files[0].file);
                data.append('_token', 'elingwaysecretkey');
                await axios.post(`${process.env.VUE_APP_URL_MEDIA}/api/storage/store-cloud`, data, {withCredentials: false}, {
                    headers: {
                        'Accept': 'application/json',
                        'content-type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.token}`
                    },
                }).then(res => {
                    this.files[0].response = res.data
                    this.form.banner = res.data.path
                }).catch(err => {
                    console.log(err.response)
                })
            },
            alert(message) {
                alert(message)
            },
            inputFile(newFile, oldFile) {
                if (newFile && !oldFile) {
                    this.$nextTick(function () {
                    this.edit = true
                    })
                }
                if (!newFile && oldFile) {
                    this.edit = false
                }
            },
            inputFilter(newFile, oldFile, prevent) {
                if (newFile && !oldFile) {
                    if (!/\.(jpg|png|jpeg)$/i.test(newFile.name)) {
                    this.alert('Your choice is not a picture')
                    return prevent()
                    }
                }
                if (newFile && (!oldFile || newFile.file !== oldFile.file)) {
                    newFile.url = ''
                    let URL = window.URL || window.webkitURL
                    if (URL && URL.createObjectURL) {
                        newFile.url = URL.createObjectURL(newFile.file)
                    }
                }
            },
            async postCourse(){
                let data = new FormData();  
                data.append('name', this.form.name);
                data.append('category', this.form.category);
                data.append('level', this.form.level);
                data.append('description', this.form.description);
                data.append('price', this.form.price);
                data.append('price_cycle_1', this.form.price_cycle_1);
                data.append('price_cycle_2', this.form.price_cycle_2);
                data.append('price_cycle_3', this.form.price_cycle_3);
                data.append('objective', this.form.objective);
                data.append('length', this.form.length);
                data.append('banner', this.form.banner);
                data.append('has_special', this.form.has_special);
                data.append('price_special', this.form.price_special);
                data.append('price_special_cycle_1', this.form.price_special_cycle_1);
                data.append('price_special_cycle_2', this.form.price_special_cycle_2);
                data.append('price_special_cycle_3', this.form.price_special_cycle_3);
                // data.append('start_date', this.form.start_date);
                data.append('start_date_1', this.form.start_date_1);
                data.append('end_date_1', this.form.end_date_1);
                data.append('start_date_2', this.form.start_date_2);
                data.append('end_date_2', this.form.end_date_2);
                data.append('start_date_3', this.form.start_date_3);
                data.append('end_date_3', this.form.end_date_3);
                data.append('start_time', this.form.start_time);
                data.append('end_time', this.form.end_time);
                data.append('cycles', this.form.cycles);
                if(this.usedPoint) {
                    data.append('point', this.form.point);
                    data.append('used_limit', this.form.used_limit);
                    data.append('type', this.form.type);
                    data.append('point_status', this.form.point_status);
                }
                await axios.post(`${process.env.VUE_APP_URL_API}/admin/course`, data, {
                    headers: {
                        'content-type': 'multipart/form-data',
                        'Authorization': `Bearer ${localStorage.token}`
                    },
                }).then(res => {
                    this.$swal({
                            toast: true,
                            title: 'Success Create New Course !',
                            text: res.data.message,
                            icon: 'success',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                    })
                    this.$router.push({ name: 'CoursesAdmin' })
                }).catch(err => {
                    if (err.response.status == 422){
                        this.validationErrors = Object.values(err.response.data.errors);
                        this.$swal({
                            toast: true,
                            title: 'Failed Create New Course !',
                            text: this.validationErrors[0][0],
                            icon: 'error',
                            position: 'top-end',
                            showConfirmButton: false,
                            timer: 3000,
                            timerProgressBar: true,
                        })
                    }
                    console.log(err.response)
                })
            }
        }
    }
</script>
